import { useStateMachine } from "little-state-machine";
import { useState, useRef } from "react";

import { ReactComponent as Copy } from "../../../../../assets/icon/copy.svg";
import Button from "../../../../../base-components/Button";
import updateAction from "../../../../updateAction";
import { formatNumber } from "../../../../../utils/helper";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "../../../../../components/ui/select";

const bankAccounts = [
  {
    value: "khan",
    label: "Хаан банк",
    icon: "/bankLogo/khan.png",
  },
  {
    value: "khas",
    label: "Хас банк",
    icon: "/bankLogo/khas.png",
  },
];

function BankAccount(props) {
  const [selectedBank, setSelectedBank] = useState("khan");

  const { state } = useStateMachine(updateAction);
  const copyText = (id) => {
    let value = document.getElementById(id).childNodes[0].textContent;
    navigator.clipboard.writeText(value);
  };
  const buttonRef = useRef(null);
  return (
    <div className="text-primary">
      <div className="mt-4">
        <Select value={selectedBank}>
          <SelectTrigger>
            <SelectValue />
          </SelectTrigger>
          <SelectContent className="w-full max-w-full overflow-hidden">
            {bankAccounts.map((ba) => {
              return (
                <SelectItem
                  key={ba.value}
                  value={ba.value}
                  icon={ba.icon}
                  onMouseDown={() => {
                    setSelectedBank(ba.value);
                    buttonRef.current.click();
                  }}
                >
                  {ba.label}
                </SelectItem>
              );
            })}
          </SelectContent>
        </Select>
      </div>
      <div className="mt-4 flex items-center justify-between">
        <div>
          <label className="text-xs text-on-surface-black-64" ref={buttonRef}>
            Дансны дугаар
          </label>
          <div id="bank-account" className="text-sm font-semibold">
            {selectedBank === "khan" ? "5107053467" : "5005840366"}
          </div>
        </div>
        <div className="mt-4 flex justify-end">
          <Button
            variant="softPrimary"
            onClick={() => copyText("bank-account")}
            icon={<Copy className="mr-3 h-4 w-4" />}
          >
            Хуулах
          </Button>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <div>
          <label className="text-xs text-on-surface-black-64">
            Хүлээн авагч
          </label>
          <div id="khuleen-avagch" className="text-sm font-semibold">
            Agula
          </div>
        </div>
        <div className="mt-4 flex justify-end">
          <Button
            variant="softPrimary"
            onClick={() => copyText("khuleen-avagch")}
            icon={<Copy className="mr-3 h-4 w-4" />}
          >
            Хуулах
          </Button>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <div>
          <label className="text-xs text-on-surface-black-64">
            Гүйлгээний утга
          </label>
          <div id="guilgeenii-utga" className="text-sm font-semibold">
            {state.sequence}
          </div>
        </div>
        <div className="mt-4 flex justify-end">
          <Button
            variant="softPrimary"
            onClick={() => copyText("guilgeenii-utga")}
            icon={<Copy className="mr-3 h-4 w-4" />}
          >
            Хуулах
          </Button>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <div>
          <label className="text-xs text-on-surface-black-64">
            Нийт үнийн дүн
          </label>
          <div id="niit-dun" className="text-sm font-semibold">
            {formatNumber(props?.amount)}₮
          </div>
        </div>
        <div className="mt-4 flex justify-end">
          <Button
            variant="softPrimary"
            onClick={() => copyText("niit-dun")}
            icon={<Copy className="mr-3 h-4 w-4" />}
          >
            Хуулах
          </Button>
        </div>
      </div>
      <div className="mt-6 font-bold">Санамж</div>
      <div className="mt-4 text-sm text-on-surface-black-64">
        Таны захиалга төлбөр төлөгдсөний дараа баталгаажих болно. Та аль ч
        банкны салбар болон интернет банкны аппликэйшн ашиглан төлбөрөө төлөх
        боломжтой.
        <br />
        <br />
        Та гүйлгээний утга буруу оруулсан тохиолдолд таны даатгал идэвхжихгүйг
        анхаарна уу.
        <br />
        <br />
        Захиалгын дэлгэрэнгүй мэдээлэл таны цахим хаяг болон утсанд давхар
        илгээгдсэн болно. Лавлах утас: 7210-1010
      </div>
    </div>
  );
}

export default BankAccount;
