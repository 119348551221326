import Service from "./BaseService";

const getTinInfo = (registerNumber) =>
  Service.get(
    `/dynamicInsurance/ebarimt-company-name?companyRegister=${registerNumber}`,
  );

const EbarimtService = {
  getTinInfo,
};

export default EbarimtService;
