import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { twMerge } from "tailwind-merge";
import { ReactComponent as Close } from "../../assets/icon/cross.svg";
import PropTypes from "prop-types";
export default function Modal(props) {
  const { title, isOpenModal, onClose = () => {}, size } = props;

  return (
    <Transition appear show={isOpenModal} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => onClose()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="flex justify-center">
                <Dialog.Panel
                  className={twMerge([
                    "w-fit min-w-[300px] transform overflow-hidden rounded-xl bg-white p-6 text-left align-middle shadow-xl transition-all",
                    size === "md" && "w-[460px]",
                    size === "sm" && "w-[300px]",
                    size === "lg" && "w-[600px] max-[500px]:w-full",
                    size === "xl" &&
                      "w-[600px] max-[500px]:w-full lg:w-[900px]",
                  ])}
                >
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-primary"
                  >
                    {title}
                    <div
                      className="absolute right-4 top-4 cursor-pointer rounded-full bg-primary-soft p-2"
                      onClick={() => onClose()}
                    >
                      <Close className="h-5 w-5" />
                    </div>
                  </Dialog.Title>
                  {props.children}
                </Dialog.Panel>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

Modal.propTypes = {
  title: PropTypes.string,
  isOpenModal: PropTypes.bool,
  onClose: PropTypes.func,
  size: PropTypes.string,
};
